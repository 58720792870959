<zef-dialog
  [open]="state.open"
  [autoFocus]="false"
  [options]="{
    maxHeight: '80vh',
    maxWidth: '900px',
    width: '80vw',
    disableClose: true
  }">
  <div
    class="__content"
    [class.is-dark-theme]="state.isDarkTheme">

    @if (state.open) {
      <zui-code-field
        class="__code-field"
        [ngrxFormControlState]="state.formState?.controls.yaml"
        [ngrxEnableFocusTracking]="true"
        [editorOptions]="{
          quickSuggestions: {
            other: true,
            comments: false,
            strings: true
          }
        }"
        [editorModel]="{
          language: 'yaml',
          fileName: 'zerops-import'
        }"
      />
    }

    <div class="__buttons">
      <div class="fx:grid  __buttons-grid">

        <div>

          <button
            type="button"
            class="__close-button  __major-button"
            (click)="onClose$.next()"
            mat-flat-button>
            Close
          </button>
        </div>

        <div class="fx:text-right">

          @if (state.mode === modes.Import) {

            @if (state.entity === dialogEntities.Project) {
              <button
                (click)="templatesPopRef.open()"
                [satPopoverAnchor]="templatesPopRef"
                mat-button
                type="button"
                class="c-button--smaller">
                <mat-icon>expand_more</mat-icon>&nbsp;<span>Select a template..</span>
              </button>
            }

            <button
              mat-button
              (click)="copy(state.formState?.controls.yaml?.value)"
              color="accent"
              type="button"
              class="c-button--smaller  fx:mr-0-5">
              <mat-icon>content_copy</mat-icon>&nbsp;<span>Copy to clipboard</span>
            </button>

            <zui-separator
              class="__utility-separator  fx:pl-0-5  fx:pr-0-75"
              [size]="'small'"
              [spacing]="'none'"
              [orientation]="'horizontal'"
            />
          }

          @if (!!state.yaml && (!state.mode || state.mode === modes.Export)) {
            <button
              class="__major-button  __copy-button"
              mat-button
              color="accent"
              type="button"
              (click)="copy(state.formState?.controls.yaml?.value); onClose$.next()">
              <strong>Copy to clipboard and close</strong>&nbsp;<mat-icon class="__content-copy-icon">content_copy</mat-icon>
            </button>
          }

          @if (state.mode === modes.Import) {
            <zef-progress-error
              [popVerticalAlign]="'above'"
              [key]="state.entity === dialogEntities.Project
                ? projectImportKey
                : serviceStackImportKey
              ">
              <button
                mat-flat-button
                color="accent"
                type="type"
                class="__major-button  __major-button--icon-right"
                (click)="state.entity === dialogEntities.Project ? onProjectImport$.next() : onServiceStackImport$.next()">
                <span>Import {{ state.entity ===  dialogEntities.Project ? 'project' : 'service' }}</span>&nbsp;<mat-icon fontSet="material-icons-outlined">play_arrow</mat-icon>
              </button>
            </zef-progress-error>
          }
        </div>

      </div>
    </div>

  </div>
</zef-dialog>

<sat-popover
  #templatesPopRef
  [autoFocus]="false"
  [restoreFocus]="false"
  hasBackdrop
  (backdropClicked)="templatesPopRef.close()"
  [verticalAlign]="'below'"
  [horizontalAlign]="'center'">
  <mat-card class="__templates-pop-card  z-manual-elevation  c-soft-elevation">
    <zef-scrollbar
      class="__templates-scrollbar"
      maxHeight="60vh">
      <mat-action-list class="__templates-action-list" dense>

        <div mat-subheader>Runtimes</div>

        @for (item of recipesByCategory[recipeCategories.Runtimes]; track item.id) {
          <button
            mat-list-item
            (click)="onSetEditorValue$.next(item.importYaml);templatesPopRef.close()"
            type="button">
            <zui-action-list-item
              class="__templates-action-list-item"
              [mode]="'dense'"
              [svgIcon]="item.logo"
              [iconFaded]="false"
              [label]="item.tag"
            />
          </button>
        }

        <div mat-subheader>Frontend frameworks</div>

        @for (item of recipesByCategory[recipeCategories.Frontend]; track item.id) {
          <button
            mat-list-item
            (click)="onSetEditorValue$.next(item.importYaml);templatesPopRef.close()"
            type="button">
            <zui-action-list-item
              class="__templates-action-list-item"
              [mode]="'dense'"
              [svgIcon]="item.logo"
              [iconFaded]="false"
              [label]="item.tag"
            />
          </button>
        }

        <div mat-subheader>Backend frameworks & Headless CMS</div>

        @for (item of recipesByCategory[recipeCategories.Backend]; track item.id) {
          <button
            mat-list-item
            (click)="onSetEditorValue$.next(item.importYaml);templatesPopRef.close()"
            type="button">
            <zui-action-list-item
              class="__templates-action-list-item"
              [mode]="'dense'"
              [svgIcon]="item.logo"
              [iconFaded]="false"
              [label]="item.tag"
            />
          </button>
        }

        <div mat-subheader>Open source apps and software</div>

        @for (item of recipesByCategory[recipeCategories.ProjectUtility]; track item.id) {
          <button
            mat-list-item
            (click)="onSetEditorValue$.next(item.importYaml);templatesPopRef.close()"
            type="button">
            <zui-action-list-item
              class="__templates-action-list-item"
              [mode]="'dense'"
              [svgIcon]="item.logo"
              [iconFaded]="false"
              [label]="item.tag"
            />
          </button>
        }

      </mat-action-list>
    </zef-scrollbar>
  </mat-card>
</sat-popover>
