<ng-container *zefMessageFormat="let mf">

  <zui-form-section
    [isZen]="isZen"
    [separator]="'none'">

    <span *zuiFormSectionTitle>
      {{ translations?.hostnameTitle }}
    </span>

    <div *zuiFormSectionDesc>
      <zui-docs-text>
        <span [innerHTML]="translations?.hostnameDesc | safeHtml"></span>
      </zui-docs-text>
    </div>

    <zef-form-field>
      <z-hostname-field
        [label]="translations?.hostnameLabel"
        [placeholder]="translations?.hostnamePlaceholder"
        [control]="state?.controls.name">
      </z-hostname-field>
    </zef-form-field>

  </zui-form-section>

  <zui-separator
    [orientation]="'vertical'"
    [size]="'medium'"
    [spacing]="'huge'"
    [position]="'center'"
  />

  <!-- DEPRECATED: start command moved to zerops.yml -->
  <!-- <zui-form-section [separatorSpacing]="'huge'">

    <span *zuiFormSectionTitle>
      Start Command
    </span>

    <div *zuiFormSectionDesc>
      A command that should start your service will be triggered after each deployment.
    </div>

    <zef-form-field>
      <mat-form-field color="accent" class="__single-field">

        <mat-label>Command</mat-label>

        <input
          matInput
          type="text"
          autocomplete="off"
          class="c-start-command-input"
          [placeholder]="'e.g. npm start, yarn, node dist/index.js'"
          [ngrxFormControlState]="state?.controls.startCommand"
          [ngrxEnableFocusTracking]="true"
        />

      </mat-form-field>
    </zef-form-field>

  </zui-form-section> -->

  <zui-form-section
    [isZen]="isZen"
    [separator]="'none'"
    class="__user-data-field">

    <span *zuiFormSectionTitle>
      {{ translations?.envVariablesTitle }}
    </span>

    <div *zuiFormSectionDesc>
      <zui-docs-text>
        <span [innerHTML]="translations?.envVariablesDesc | safeHtml"></span>
      </zui-docs-text>
    </div>

  </zui-form-section>

  <zef-form-field>
    <zui-code-field
      class="__code-field"
      [editorOptions]="{ language: 'ini' }"
      [ngrxFormControlState]="state?.controls.userData"
      [ngrxEnableFocusTracking]="true">
    </zui-code-field>
  </zef-form-field>

  <zui-separator
    [orientation]="'vertical'"
    [size]="'medium'"
    [spacing]="'huge'"
    [position]="'center'"
  />

  <zui-form-section
    [isZen]="isZen"
    [separator]="'none'"
    class="__scaling-section">

    <span *zuiFormSectionTitle>
      Automatic Scaling
    </span>

    <div *zuiFormSectionDesc>
      <zui-docs-text>
        <span [innerHTML]="translations?.scalingDesc | safeHtml"></span>
      </zui-docs-text>
    </div>

    <zef-form-field>
      <zui-autoscaling-form-field
        [serviceStackType]="serviceStackType"
        [ngrxFormControlState]="state?.controls.customAutoscaling"
        [defaultHorizontalAutoscaling]="defaultHorizontalAutoscaling"
        [defaultVerticalAutoscaling]="defaultVerticalAutoscaling">
      </zui-autoscaling-form-field>
    </zef-form-field>

  </zui-form-section>

  <zui-separator
    [orientation]="'vertical'"
    [size]="'medium'"
    [spacing]="'huge'"
    [position]="'center'"
  />

  <zui-form-section
    class="__start-empty-section"
    [isZen]="isZen"
    [separator]="'none'">

    <span *zuiFormSectionTitle>
      Start the Service Empty?
    </span>

    <div *zuiFormSectionDesc>
      <zui-docs-text>
        <span [innerHTML]="translations?.startEmptyDesc | safeHtml"></span>
      </zui-docs-text>
    </div>

    <zef-form-field
      class="__start-empty-toggle"
      last
      [appearance]="'slide-toggle'">
      <mat-slide-toggle
        [ngrxFormControlState]="state?.controls.startWithoutCode"
        [ngrxEnableFocusTracking]="true">
        <strong>Start the empty service without requiring your code</strong>
      </mat-slide-toggle>
    </zef-form-field>

  </zui-form-section>

  <zui-separator
    [orientation]="'vertical'"
    [size]="'medium'"
    [spacing]="'large'"
    [position]="'center'"
  />

</ng-container>
