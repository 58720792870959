import { HashMap } from '@ngneat/transloco';
import { ServiceStack, ServiceStackStatuses, ServiceStackTypes } from './service-stack.model';

export const SERVICES_WITH_ACCESS_DETAIL = [
  ServiceStackTypes.Elasticsearch,
  ServiceStackTypes.MariaDb,
  ServiceStackTypes.Qdrant,
  ServiceStackTypes.MongoDb,
  ServiceStackTypes.Redis,
  ServiceStackTypes.Valkey,
  ServiceStackTypes.Postgres,
  ServiceStackTypes.RabbitMq,
  ServiceStackTypes.Nats,
  ServiceStackTypes.Kafka,
];

export const SERVICES_WITH_CONFIGURATION = [
  // ServiceStackTypes.Static,
  ServiceStackTypes.Nginx,
  ServiceStackTypes.PhpNginx,
  // ServiceStackTypes.SharedStorage,
  // ServiceStackTypes.ObjectStorage
];

export const SERVICES_WITH_BACKUP = [
  ServiceStackTypes.MariaDb,
  ServiceStackTypes.Postgres,
  ServiceStackTypes.Qdrant,
  ServiceStackTypes.SharedStorage,
  ServiceStackTypes.Nats
];

export const SERVICES_WITHOUT_PUBLIC_ROUTING = [
  ServiceStackTypes.ObjectStorage,
  ServiceStackTypes.Elasticsearch,
  ServiceStackTypes.MariaDb,
  ServiceStackTypes.MongoDb,
  ServiceStackTypes.Postgres,
  ServiceStackTypes.Redis,
  ServiceStackTypes.Valkey,
  ServiceStackTypes.Qdrant,
  ServiceStackTypes.Nats,
  ServiceStackTypes.Kafka,
  ServiceStackTypes.SharedStorage,
];

export const DISABLED_MENU_ITEM_STATUSES = [
  ServiceStackStatuses.New,
  ServiceStackStatuses.Creating,
  ServiceStackStatuses.ReadyToDeploy
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getConfigurationMenuItemTranslation = (typeId: ServiceStackTypes, translations: HashMap<any>) => {

  if (typeId === ServiceStackTypes.Static) {
    return {
      name: translations.menuStaticNginxConfigName,
      desc: translations.menuStaticNginxConfigDesc
    };
  }

  if (typeId === ServiceStackTypes.Nginx || typeId === ServiceStackTypes.PhpNginx) {
    return {
      name: translations.menuNginxConfigName,
      desc: translations.menuNginxConfigDesc
    };
  }

  if (typeId === ServiceStackTypes.ObjectStorage ) {
    return {
      name: translations.menuObjectStorageConfigName,
      desc: translations.menuObjectStorageConfigDesc
    };
  }

  if (typeId === ServiceStackTypes.SharedStorage ) {
    return {
      name: translations.menuSharedStorageConfigName,
      desc: translations.menuSharedStorageConfigDesc
    };
  }

};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getLogMenuItemTranslation = (typeId: ServiceStackTypes, translations: HashMap<any>) => {

  if (
    typeId === ServiceStackTypes.Redis
    || typeId === ServiceStackTypes.MariaDb
    || typeId === ServiceStackTypes.Postgres
    || typeId === ServiceStackTypes.Nats
    || typeId === ServiceStackTypes.Kafka
  ) {
    return {
      name: translations.databaseMenuLogName,
      desc: translations.databaseMenuLogDesc
    };
  }

  return {
    name: translations.menuLogName,
    desc: translations.menuLogDesc
  };

};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getRoutingMenuItemTranslation = (typeId: ServiceStackTypes, translations: HashMap<any>) => {
  if (typeId === ServiceStackTypes.Static || typeId === ServiceStackTypes.Nginx || typeId === ServiceStackTypes.PhpApache || typeId === ServiceStackTypes.PhpNginx) {
    return {
      name: translations.menuRoutingName,
      desc: translations.menuRoutingNginxDesc
    };
  }

  return {
    name: translations.menuRoutingName,
    desc: translations.menuRoutingNginxDesc
  };

};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getAccessDetailsMenuItemTranslation = (typeId: ServiceStackTypes, translations: HashMap<any>) => {

  if (typeId === ServiceStackTypes.MariaDb
    || typeId === ServiceStackTypes.MongoDb
    || typeId === ServiceStackTypes.Redis
    || typeId === ServiceStackTypes.Valkey
    || typeId === ServiceStackTypes.Qdrant
    || typeId === ServiceStackTypes.Postgres) {

    return {
      name: translations.menuAccessDetailsName,
      desc: translations.menuAccessDetailsDesc
    };
  }

  if (typeId === ServiceStackTypes.Elasticsearch) {
    return {
      name: translations.menuAccessDetailsName,
      desc: translations.menuAccessDetailsSearchEngineDesc
    };
  }

  if (
    typeId === ServiceStackTypes.RabbitMq
    || typeId === ServiceStackTypes.Nats
    || typeId === ServiceStackTypes.Kafka
  ) {
    return {
      name: translations.menuAccessDetailsName,
      desc: translations.menuAccessDetailsMessageBrokerDesc
    };
  }

};

export const containsPublicAccess = (v: ServiceStack[]) => {
  return v.some((d) => (d.hasPublicHttpRoutingAccess || d.hasPublicPortRoutingAccess));
};

export const containsNonSystemServiceStacks = (v: ServiceStack[]) => {
  return v.some((d) => !d.isSystem);
}
