<zef-scrollbar [maxHeight]="maxHeight">
  <div class="__scroll-inner">

    <!-- running -->
    <div class="__running" *ngIf="state.runningProcesses?.length">
      <div
        *ngFor="let item of state.runningProcesses; let last = last; trackBy: trackById;"
        class="c-processes-card-item __item __item--running"
        [class.is-last]="last">
        <zui-process-item
          [translations]="processItemTranslations"
          [data]="item"
          [pipelineErrors]="state.pipelineErrors"
          (pipelineDetailClicked)="pipelineDetailClicked.emit($event)"
          (buildCancelClicked)="buildCancelClicked.emit($event)"
          (internalLinkClicked)="internalLinkClicked.emit()">
        </zui-process-item>
      </div>
    </div>

    <zui-separator
      *ngIf="state.runningProcesses?.length && state.displayedPendingProcesses?.length"
      class="__separator-main"
      [position]="'start'"
      [size]="'small'"
      [spacing]="'none'">
    </zui-separator>

    <!-- pending -->
    <div
      class="__pending-wrap"
      [class.has-faded-items]="state.displayedPendingProcesses?.length < state.pendingProcesses?.length"
      *ngIf="state.displayedPendingProcesses?.length">

      <div
        class="c-processes-card-item  __item  __item--pending"
        [class.is-last]="last"
        *ngFor="let item of state.displayedPendingProcesses; let last = last; trackBy: trackById; "
        [class.is-loading]="!!processCancelProgressesMap[item.id]">
        <zui-process-item
          [translations]="processItemTranslations"
          [data]="item"
          [hideCancel]="hideCancel"
          (cancelClicked)="cancelClicked.emit($event)"
          (internalLinkClicked)="internalLinkClicked.emit()">
        </zui-process-item>
      </div>

    </div>

    <div
      *ngIf="state.displayedPendingProcesses?.length < state.pendingProcesses?.length"
      class="__button-wrap">
        <button
          class="c-button c-button--smaller"
          (click)="showAllPendingProcesses$.next($event)"
          type="button"
          mat-button>

          <mat-icon>keyboard_arrow_down</mat-icon>

          <span>Show all queued processes</span>

        </button>
    </div>

  </div>
</zef-scrollbar>
